var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "grid",
            attrs: {
              title: "LBL0003333",
              tableId: "grid",
              columns: _vm.grid.columns,
              data: _vm.grid.data,
              isFullScreen: false,
              columnSetting: false,
              filtering: false,
            },
            on: { linkClick: _vm.licensePop },
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }